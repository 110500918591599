import React, { useState } from "react";
import Link from "next/link"
import { Alert, Container, Row, Col, Button, Badge, Form, InputGroup } from "react-bootstrap"
import footerContent from "../data/footer.json"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons"
import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTwitter,
  faVimeo,
  faLinkedinIn, faLinkedin
} from "@fortawesome/free-brands-svg-icons"

//
const Footer = () => {
  const [sendEmail, setSendEmail] = useState(0);

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = {};
    Array.from(e.currentTarget.elements).forEach(field => {
      if (!field.name) return;
      formData[field.name] = field.value;
    });

    const date = new Date();
    const url = '/api/mail/subscriber';
    const res = await fetch(url, {
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    });
    const result = await res.json();

    if (res.status === 201) {
      setSendEmail(1);
    } else if (res.status === 400) {
      setSendEmail(2);
    }
  }

  const mainFooterContent = footerContent.mainContent;
  const privaciesData = footerContent.privacies;

  return (
    <footer className="position-relative z-index-10 d-print-none">
      < div className="py-6 fw-light bg-gray-900 text-gray-300">
        <Container>
          <Row>
            {mainFooterContent &&
              mainFooterContent.map((item) => (
                <Col
                  key={item.title}
                  lg={item.lg && item.lg}
                  md={item.md && item.md}
                  className="mb-5 mb-lg-0"
                >
                  <div className="fw-bold text-uppercase  mb-3">
                    {item.title}
                  </div>
                  {item.links && (
                    <ul className="list-unstyled ">
                      {item.links.map((link) => (
                        <li key={link.title}>
                          <Link href={link.link}>
                            <a className="text-gray-600">
                              {link.title}
                              {link.new && (
                                <Badge variant="info" bg="info-light" className="ml-1">
                                  New
                                </Badge>
                              )}
                            </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                  {item.social && (
                    <ul className="list-inline">
                      {item.social.map((socialIcon) => (
                        <li key={socialIcon.title} className="list-inline-item">
                          <a
                            href={socialIcon.link}
                            target="_blank"
                            title={socialIcon.title}
                            className="text-light-emphasis text-hover-primary "
                          >
                            <FontAwesomeIcon icon={faLinkedin} />
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                  {item.form && (
                    (sendEmail > 0) ? (
                      <Alert variant="light" className="bg-transparent border-dark">
                        {sendEmail === 1 ? 'Thank you for getting in touch!' : "Contact already exist"}
                      </Alert>) : (
                      <Form id="newsletter-form" onSubmit={onSubmit}>
                        <InputGroup className="input-group mb-3">
                          <Form.Control
                            type="email"
                            name="email"
                            id="email"
                            placeholder={item.form.placeholder}
                            aria-label={item.form.placeholder}
                            required
                            className="bg-transparent border-dark border-end-0"
                          />
                          <Button
                            className="border-start-0"
                            variant="outline-dark"
                            type="submit"
                            aria-label="Search"
                          >
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className="text-lg"
                            />
                          </Button>
                        </InputGroup>
                      </Form>
                    )
                  )}
                </Col>
              ))}
          </Row>
        </Container>
        <div className="border-top border-dark p-2"></div>
        <Container>
          <Row >
            <div className="d-flex gap-3 flex-column flex-md-row">
              <span>{`© ${new Date().getFullYear()} VendorLand. All rights reserved.`}</span>
              {privaciesData &&
                <div className="d-flex">
                  {privaciesData.map((privacy) =>
                    <Link href={privacy.link} key={privacy.title}>
                      <a className="text-gray-600">
                        {privacy.title}
                      </a>
                    </Link>)}
                </div>
              }
            </div>
          </Row>
        </Container>
      </div>
      {/*
      <div className="py-4 fw-light bg-gray-800 text-gray-300">
        <Container>
          <Row className="align-items-center">
            <Col md="6" className="text-center text-md-left">
              <p className="text-sm mb-md-0">
                © 2021, VendorLand. All rights reserved.
              </p>
            </Col>
           <Col md="6">
              <ul className="list-inline mb-0 mt-2 mt-md-0 text-center text-md-right">
                <li className="list-inline-item">
                  <img
                    width="32"
                    height="32"
                    src="/content/svg/visa.svg"
                    alt="..."
                    className="w-2rem"
                  />
                </li>
                <li className="list-inline-item">
                  <img
                    src="/content/svg/mastercard.svg"
                    width="32"
                    height="32"
                    alt="..."
                    className="w-2rem"
                  />
                </li>
                <li className="list-inline-item">
                  <img
                    src="/content/svg/paypal.svg"
                    width="32"
                    height="32"
                    alt="..."
                    className="w-2rem"
                  />
                </li>
                <li className="list-inline-item">
                  <img
                    src="/content/svg/western-union.svg"
                    width="32"
                    height="32"
                    alt="..."
                    className="w-2rem"
                  />
                </li>
              </ul>
            </Col>
           </Row>
        </Container>
      </div>
*/}
    </footer>
  )
}

export default Footer
